import clsx from "clsx";
import { useState, useRef } from "react";
import useLineClamp from "use-line-clamp";
import DOMPurify from "dompurify";

type ExpandableSummaryProps = {
  summary: string;
  className?: string;
  useHTML?: boolean;
};

const ExpandableSummary = ({
  summary,
  useHTML = false,
}: ExpandableSummaryProps) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const clamps = useLineClamp(ref, { lines: 2 });
  const [isExpanded, setIsExpanded] = useState(false);

  // sanitize function cleans and removes potentially dangerous parts of the HTML content
  const sanitizedHTML = useHTML ? DOMPurify.sanitize(summary) : summary;

  return (
    <div className={clsx("body-text-sm relative max-w-prose")}>
      {useHTML ? (
        <div
          ref={ref}
          className={clsx(isExpanded ? "" : "line-clamp-2")}
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        />
      ) : (
        <div ref={ref} className={clsx(isExpanded ? "" : "line-clamp-2")}>
          {summary}
        </div>
      )}
      {clamps && (
        <button
          data-nosnippet // to exclude the See more from being shown in Google Search results
          onClick={() => setIsExpanded(!isExpanded)}
          className={clsx(
            "underline decoration-1 underline-offset-2 transition ease-out hover:opacity-75"
          )}
        >
          {isExpanded ? "" : "See more"}
        </button>
      )}
    </div>
  );
};

export default ExpandableSummary;
